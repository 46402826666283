import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ARROW, LOCK, LOGO} from '../../assets';
import {ResponseCheckUuidtransaction} from '../../model';
import {GuichetUniqueService} from '../../services';
import {Loading} from './component';
import {Home} from './Home';
import {MobileMoney} from './MobileMoney';
import { CardBank } from './CardBank';
import {NotFound} from './NotFound';
import {PayementValidate} from './PayementValidate';
import {usePage} from './usePage';
import {Wallet} from './Wallet';

function GuichetPage() {
    const {
        etat,
        loading,
        errorUuid,
        checkUuidTransaction,
        setEtat,
    } = usePage()
    return (
        <div>
            {loading ? <Loading/> :
                (errorUuid ? <NotFound/> :
                    <div className='body'>
                        <div className='container mt-2 body-child'>
                            {etat == 1 ? <Home setEtat={setEtat} transaction={checkUuidTransaction}/> :
                                (
                                    <div className="mobileMoney mt-5">
                                        <div className="card card-mobile-money">
                                            <div className="header-mobile-money">
                                                <div className="card-header">
                                                    {etat != 4 ?
                                                        <div className="header-mobile-money-parent">
                                                            <div className="header-mobile-money-child mt-3">
                                                                <div className="header-mobile-money-child-leading"
                                                                     title="Retour" onClick={e => setEtat(1)}>
                                                                    <div className="leading-image">
                                                                        <img src={ARROW}/>
                                                                    </div>
                                                                    <div className="leading-title">
                                                                        Retour
                                                                    </div>
                                                                </div>
                                                                <div className="closeCard">
                                                                    Paiement avec Alimha
                                                                </div>
                                                            </div>
                                                        </div> : <div className="header-card-title text-center">Paiement avec Alimha</div>}
                                                </div>
                                            </div>
                                            <div className="card-body body-mobile-money">
                                                {etat != 4 && <div className="row">
                                                    <div className="row1tilte">
                                                        {etat == 2 ? "Wallet Paiement": ( etat ==3 ? "Paiement par Mobile Money":"Paiement par Carte Bancaire")}
                                                    </div>
                                                    <hr className='line1'/>
                                                </div>}
                                                {etat == 2 ?
                                                    <Wallet transaction={checkUuidTransaction}/> : (etat == 3 ?
                                                        <MobileMoney
                                                            transaction={checkUuidTransaction}/> : (etat == 5 ? <CardBank transaction={checkUuidTransaction}/>: <PayementValidate/>) )
                                                }
                                                <hr className='line1'/>
                                                <div className="footer mt-1">
                                                    <img src={LOCK}/>
                                                </div>
                                                <div className="footer">
                                                    <div className='lastTitle mt-1'>
                                                        Sécurisé par <span className='footer-title'
                                                                           style={{color: "#076df3"}}>Ali</span><span
                                                        className='footer-title'>mha</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className='imageFooter'><img src={LOGO}/></div>
                        </div>
                    </div>)
            }
        </div>
    )
}

export default GuichetPage;