import React, { useEffect } from 'react';
import { EXPRESSO, LOCK, LOGO, MASTERCARD, MOBILE, OM, PAYPAL, VISA, WALLET, WAVE,CARTE } from '../../../assets';
import { HomeProps } from './MobileMoneyProps';

const Home : React.FC<HomeProps> = ({setEtat,transaction})=>{
    // console.log(transaction);
    return (
        <div className="homecopay">
                    <div className='containerHeader'>
                        <div className='titlecopay'>RECAPITULATIF</div>
                        <div className='titlecopayright' title={transaction?.plateforme.nom}><img src={transaction?.plateforme.urlLogo} style={{height: "30px"}}/></div>
                    </div>
                    <hr className='line1'/>
                    <div className="card cardContainerTotal">
                        <div className="card-body">
                            <div className='containerTotal'>
                                <div className="line2Total">
                                    Total
                                </div>
                                <div className="line2montant">
                                    {transaction?.transaction.montantPaye} XOF
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='containerImage mt-3 '>
                        <div className='litteTitle'>
                            Paiement avec Alimha
                        </div>
                        <div className="image-partenaire">
                            <img src={WAVE}/>
                            <img  src={OM}/>
                            <img src={VISA}/>
                            <img src={MASTERCARD}/>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="container-alert-message alert-message mt-">
                            <span className='alert-message'>Veuiller selectionner un type de paiement SVP !</span>
                        </div>
                    </div>
                    
                    <div className='row justify-content-around mt-4'>
                        {transaction?.services?.mobilemoney && <div className="text-center col-12 col-md-6 mb-3 d-none">
                            <button onClick={e=>setEtat(5)} className="btn-moyen-paiement">
                                <img  src={CARTE}/>
                            </button>
                            <div className='titleThemeCardType mt-3'>
                                Carte Bancaire
                            </div>
                        </div>}
                        {transaction?.services.wallet && <div className="text-center col-6 col-md-6 mb-3">
                            <button onClick={e=>setEtat(2)} className="btn-moyen-paiement">
                                <img  src={WALLET}/>
                            </button>
                            <div className='titleThemeCardType mt-3'>
                                Wave
                            </div>
                        </div>}
                        {transaction?.services.mobilemoney && <div className="text-center col-6 col-md-6 mb-3">
                            <button onClick={e=>setEtat(3)} className="btn-moyen-paiement">
                                <img  src={MOBILE}/>
                            </button>
                            <div className='titleThemeCardType mt-3'>
                                Mobile Money
                            </div>
                        </div>}
                    </div>
                    {/*<div className='d-flex justify-content-around mt-4'>*/}
                    {/*     {transaction?.services?.mobilemoney && <div className="mobile">*/}
                    {/*         <div onClick={e=>setEtat(5)} className="card themeCardType" style={{width: 100,height:100}}>*/}
                    {/*            <div className="card-body">*/}
                    {/*                <div className="imageType3">*/}
                    {/*                    <img  src={CARTE}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='titleThemeCardType mt-3'>*/}
                    {/*            Carte Bancaire*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}
                    {/*    {transaction?.services.wallet && <div className="wallet">*/}
                    {/*         <div onClick={e=>setEtat(2)} className="card themeCardType" style={{width: 100,height:100}}>*/}
                    {/*            <div className="card-body">*/}
                    {/*              <div className="imageType">*/}
                    {/*                <img  src={WALLET}/>*/}
                    {/*              </div>*/}
                    
                    {/*            </div>*/}
                    {/*            */}
                    {/*        </div>*/}
                    {/*        <div className='titleThemeCardType mt-3'>*/}
                    {/*            Wallet*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}
                    {/*    */}
                    {/*    {transaction?.services?.mobilemoney && <div className="mobile">*/}
                    {/*         <div onClick={e=>setEtat(3)} className="card themeCardType" style={{width: 100,height:100}}>*/}
                    {/*            <div className="card-body">*/}
                    {/*                <div className="imageType2">*/}
                    {/*                    <img  src={MOBILE}/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='titleThemeCardType mt-3'>*/}
                    {/*            Mobile Money*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}
                    {/*    */}
                    {/*</div>*/}
                    <hr className='line1'/>
                    <div className="footer mt-1">
                        <img src={LOCK} />
                    </div>
                    <div className="footer">
                                    <div className='lastTitle mt-1'>
                                    Sécurisé par  <span className='footer-title' style={{color:"#076df3"}}>Ali</span><span className='footer-title'>mha</span>
                                    </div>
                    </div>
        </div>
    );
}

export default Home;